import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { useLocation } from '@reach/router';
import classNames from 'classnames';
import { AnimatePresence, domAnimation, LazyMotion, m } from 'framer-motion';
import kebabCase from 'lodash/kebabCase';

import Button from 'components/atoms/Button';
import Typography, { TypographyProps } from 'components/atoms/Typography';
import Container from 'components/molecules/Container';
import { StyledFilterWrapper } from 'components/organisms/FilterWrapper';
import RelatedList from 'components/organisms/RelatedList';
import {
  StyledCaseStudies,
  StyledCaseStudiesHeading,
  StyledCaseStudiesImage,
  StyledRelatedListsContainer,
} from 'components/templates/containers';
import Layout from 'components/templates/Layout';

import { useCaseStudiesFilter } from 'hooks/useCaseStudiesFilter';

const CaseStudiesListingPage = ({
  data: {
    contentfulCaseStudiesListingPage: {
      caseStudyList,
      heading,
      headingImage,
      description,
      relatedListsProportions,
      tag,
      variant,
      relatedList,
      seo,
    },
  },
}: CaseStudiesListingPageTypes.CaseStudiesListingPageProps) => {
  const location = useLocation();
  const [selectedTag, setSelectedTag] = useState(tag?.[0].value || '');

  const filteredList = useCaseStudiesFilter(selectedTag, caseStudyList);

  const getQueryParam = () => {
    const { search } = location;
    const params = new URLSearchParams(search);
    setSelectedTag(params.get('tag') || '');
  };

  const titleTypographyProps: Record<
    CaseStudiesListingPageTypes.CaseStudiesListingVariant,
    TypographyProps
  > = {
    blog: {
      size: 72,
      transform: 'uppercase',
      padding: { left: { base: 16, md: 32, xl: 80 } },
    },
    'case-studies': {
      size: { base: 36, md: 48, lg: 60, xl: 72 },
      padding: { left: { base: 16, md: 32, xl: 80 }, right: { base: 16, md: 32, xl: 80 } },
    },
  };

  useEffect(() => {
    getQueryParam();

    window.addEventListener('locationchange', getQueryParam);

    return () => window.removeEventListener('locationchange', getQueryParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Layout seo={seo} bgColor="gray-50">
      <StyledCaseStudies
        data-page={variant}
        contentWidth={1440}
        className={classNames({ 'extend-padding': !relatedList })}
        pb={relatedList ? undefined : { base: 80, md: 96, lg: 136, xl: 200 }}
      >
        <StyledCaseStudiesHeading data-variant={variant}>
          {headingImage ? (
            <StyledCaseStudiesImage image={headingImage.image} alt={headingImage.alt} />
          ) : null}
          <Typography
            color="gray-900"
            fontWeight={500}
            {...titleTypographyProps[variant]}
            asEl="h1"
          >
            {heading}
          </Typography>
          {description ? (
            <Typography
              size={{ base: 16, md: 20, lg: 24 }}
              padding={{ left: { base: 16, md: 0 } }}
              color="gray-800"
              lineHeight={1.4}
            >
              {renderRichText(description)}
            </Typography>
          ) : null}
        </StyledCaseStudiesHeading>
        {tag ? (
          <StyledFilterWrapper>
            {tag.map(({ icon, title, value, ariaLabel }) => (
              <Button
                key={value}
                className={selectedTag === kebabCase(value || '') ? 'active' : ''}
                variant="quaternary"
                icon={icon}
                label={title}
                ariaLabel={ariaLabel}
                typography={{
                  color: 'gray-700',
                  fontWeight: 400,
                  size: { base: 14, lg: 16 },
                  padding: { bottom: 5, top: 5 },
                }}
                disabled={!caseStudyList.some((el) => el.tag === value) && value !== null}
                onClick={() => {
                  const url = new URL(location.href);
                  if (value) url.searchParams.set('tag', kebabCase(value));
                  else url.searchParams.delete('tag');

                  window.history.pushState({ filteredList }, '', url);

                  setSelectedTag(kebabCase(value || ''));
                }}
              />
            ))}
          </StyledFilterWrapper>
        ) : null}
        <StyledRelatedListsContainer mt={{ base: 48, md: 64, lg: 80 }} noPadding>
          {filteredList
            ? relatedListsProportions?.map((el, index) => {
                if (filteredList.length) {
                  const elementsToDisplay = filteredList.splice(0, el.split(':').length);

                  return (
                    <LazyMotion key={`related-list-${el}-${index}`} features={domAnimation}>
                      <AnimatePresence>
                        <m.div
                          layout
                          animate={{ opacity: 1 }}
                          initial={{ opacity: 0 }}
                          transition={{ duration: 1 }}
                          exit={{ opacity: 0 }}
                          className={`related-list-${el}-${index}`}
                        >
                          <RelatedList list={elementsToDisplay} proportions={el} />
                        </m.div>
                      </AnimatePresence>
                    </LazyMotion>
                  );
                }

                return null;
              })
            : null}
        </StyledRelatedListsContainer>
        {filteredList?.map((el) => (
          <AnimatePresence key={`related-list-${el.id}`}>
            <m.div
              layout
              animate={{ opacity: 1 }}
              initial={{ opacity: 0 }}
              transition={{ duration: 1 }}
              exit={{ opacity: 0 }}
            >
              <RelatedList list={[el]} />
            </m.div>
          </AnimatePresence>
        ))}
      </StyledCaseStudies>
      {relatedList ? (
        <Container
          mt={{ base: 80, md: 96, lg: 136, xl: 160 }}
          pb={{ base: 80, md: 96, lg: 136, xl: 200 }}
        >
          <RelatedList
            variant={relatedList.variant}
            heading={relatedList.title}
            list={relatedList.relatedSites}
            proportions={relatedList.proportions}
            button={relatedList.button?.[0]}
          />
        </Container>
      ) : null}
    </Layout>
  );
};

export default CaseStudiesListingPage;

export const query = graphql`
  query CaseStudiesListingPageQuery($slug: String) {
    contentfulCaseStudiesListingPage(slug: { eq: $slug }) {
      seo {
        ...SeoFragment
      }
      heading
      headingImage {
        ...ImageDataStructureFragment
      }
      variant
      # description {
      #   raw
      # }
      relatedListsProportions
      tag {
        icon {
          ...IconFragment
        }
        title
        value
        ariaLabel
      }
      caseStudyList {
        ... on ContentfulCaseStudiesDetailPage {
          id
          tag
          card {
            ...CardFragment
          }
        }
      }
      # relatedList {
      #   ...RelatedListFragment
      # }
    }
  }
`;
