import styled from 'styled-components';
import { breakpoint, container } from 'styles';

import { StyledButton } from 'components/atoms/Button';
import { StyledTypography } from 'components/atoms/Typography';

export const StyledFilterWrapper = styled.div`
  --wrapper: var(--wrapper-medium);
  ${container}

  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-xxxxxs);
  justify-content: space-between;
  padding: 0;

  ${StyledButton} {
    flex-basis: 100%;
    max-width: unset;

    ${breakpoint.xxs} {
      flex-basis: calc(50% - var(--gap-xxxxs));
    }

    & > div {
      width: 100%;
    }
  }

  ${StyledButton} {
    ${StyledTypography} {
      min-width: 110px;
    }
  }

  @media screen and (min-width: 440px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  ${breakpoint.md} {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: unset;

    ${StyledButton}, ${StyledButton}:first-child {
      flex: 1;
      flex-basis: auto;
    }
  }

  ${breakpoint.lg} {
    gap: var(--gap-xs);
    justify-content: flex-start;
    margin: 0;
  }

  ${breakpoint.xl} {
    gap: var(--gap-md);

    ${StyledButton}, ${StyledButton}:first-child {
      flex: unset;

      ${StyledTypography} {
        min-width: 160px;
      }
    }
  }
`;
