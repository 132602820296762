import styled from 'styled-components';
import { breakpoint, container } from 'styles';

import { StyledButton } from 'components/atoms/Button/Button.styles';
import GlassTile from 'components/atoms/GlassTile';
import Image from 'components/atoms/Image';
import { StyledTypography } from 'components/atoms/Typography';
import { CardVariant } from 'components/molecules/Card';
import { StyledCard } from 'components/molecules/Card/Card.styles';
import Container, { ContainerProps } from 'components/molecules/Container';

import { RelatedListVariant } from './models.d';

const { SECONDARY } = CardVariant;

export const StyledContainer = styled(Container)<ContainerProps>`
  position: relative;
  padding: 0;
`;

export const StyledWrapper = styled.div`
  z-index: var(--z-index-ground);

  ${StyledTypography} {
    b {
      font-weight: 500;
    }
  }

  ${StyledCard} {
    &[data-variant='${SECONDARY}'] {
      ${breakpoint.sm} {
        grid-template-rows: 250px repeat(2, auto);
      }

      ${breakpoint.xl} {
        grid-template-rows: 324px repeat(2, auto);
      }
    }
  }

  &[data-variant='${RelatedListVariant.SECONDARY}'] {
    display: grid;
    grid-template-columns: 1fr;
    align-items: baseline;

    > * {
      &:first-child {
        padding-right: var(--gap-xxs);
      }

      &:last-child {
        place-self: center;
        margin-top: var(--gap-xxl);
        margin-right: var(--gap-xxs);
      }
    }

    ${breakpoint.md} {
      grid-template-columns: var(--proportion, 1fr) 170px;

      > * {
        &:first-child {
          grid-column: 1/3;
        }

        &:last-child {
          place-self: start end;
          margin-top: 102px;
          margin-right: 0;
        }
      }
    }

    ${breakpoint.lg} {
      grid-template-columns: var(--proportion, 1fr) 200px;
    }

    ${breakpoint.xl} {
      grid-template-columns: var(--proportion, 1fr) 280px;
    }
  }

  &[data-variant='${RelatedListVariant.SECONDARY_REVERSE}'] {
    display: grid;
    grid-template-columns: 1fr;
    align-items: baseline;

    ${StyledButton} {
      margin-bottom: var(--gap-lg);
    }

    ${breakpoint.lg} {
      .button-section {
        align-self: start;
        margin-top: var(--gap-xxxxxl);
      }
    }

    ${breakpoint.xl} {
      grid-template-columns: 300px 1fr;
      column-gap: var(--gap-md);
    }
  }
`;

export const StyledRelatedList = styled.div`
  --wrapper: 1440px;

  position: relative;
  ${container}

  display: grid;
  gap: var(--gap-xxxxs);

  ${breakpoint.md} {
    grid-template-columns: var(--proportion, 1fr);
  }

  &[data-variant='${RelatedListVariant.PRIMARY}'] {
    grid-template-columns: 1fr;
    gap: var(--gap-md);
    align-items: baseline;

    ${breakpoint.md} {
      grid-template-columns: var(--proportion, 1fr);
      gap: var(--gap-xxs);
    }

    ${breakpoint.lg} {
      gap: var(--gap-md);
    }
  }

  &[data-variant='${RelatedListVariant.SECONDARY}'] {
    grid-template-columns: 1fr;
    gap: var(--gap-md);
    align-items: baseline;

    ${breakpoint.md} {
      grid-template-columns: var(--proportion, 1fr);
      gap: var(--gap-xxxxs);
    }
  }

  &[data-variant='${RelatedListVariant.SECONDARY_REVERSE}'] {
    gap: var(--gap-md);
  }

  &[data-variant='${RelatedListVariant.OUR_CASE_STUDY}']:nth-of-type(2) {
    display: none;

    ${breakpoint.md} {
      display: grid;
      margin-bottom: var(--gap-xxxxs);
    }

    ${breakpoint.lg} {
      margin-bottom: var(--gap-md);
    }

    ${breakpoint.xl} {
      margin-bottom: var(--gap-xxxxxl);
    }
  }
`;

export const RelatedGlassTile = styled(GlassTile)`
  position: absolute;
  top: 198px;
  left: 0;
  z-index: var(--z-index-zero);
  width: 100%;
  height: 106px;
  background: rgba(255, 255, 255, 50%);
  border-radius: var(--border-radius-md);
  transform: translateY(-100%);

  ${breakpoint.sm} {
    top: 250px;
  }

  ${breakpoint.md} {
    top: 238px;
    height: 160px;
  }

  ${breakpoint.xl} {
    top: 316px;
  }

  &[data-variant='global'] {
    display: none;

    ${breakpoint.md} {
      display: block;
    }
  }

  &[data-variant='local'] {
    display: block;

    ${breakpoint.md} {
      display: none;
    }
  }
`;

export const StyledCircleImage = styled(Image)`
  display: none;

  ${breakpoint.xl} {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: var(--z-index-underground);
    display: block;
    width: 220px;
    height: 220px;
    transform: translate(-70%, 15%);
  }
`;

export const StyledButtonsWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: var(--gap-xxxxs);
  align-items: center;
  justify-content: center;

  ${breakpoint.md} {
    flex-direction: row;
    gap: var(--gap-xxs);
  }
`;
