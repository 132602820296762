import React from 'react';

import Button, { ButtonType } from 'components/atoms/Button';
import { TypographyProps } from 'components/atoms/Typography';

import { RelatedListVariant } from './models.d';

export const descriptionProps: {
  [key in
    | RelatedListVariant.OUR_CASE_STUDY
    | RelatedListVariant.SECONDARY_REVERSE]: TypographyProps;
} = {
  [RelatedListVariant.OUR_CASE_STUDY]: {
    size: { base: 36, lg: 48, xl: 72 },
    padding: {
      left: { base: 16, md: 32, xl: 48, xxl: 0 },
      right: { base: 16, md: 32, xl: 48, xxl: 0 },
      bottom: { base: 8, lg: 16, xl: 80 },
    },
    lineHeight: 1.2,
    fontWeight: 300,
    color: 'gray-800',
    letterSpacing: '-0.02em',
  },
  [RelatedListVariant.SECONDARY_REVERSE]: {
    size: 16,
    padding: { bottom: 24 },
    color: 'gray-800',
    lineHeight: 1.5,
    fontWeight: 300,
  },
};

export const renderButtons = (button: ButtonType | ButtonType[]) =>
  Array.isArray(button) && button?.length ? (
    button.map((btn, idx) => (
      <Button
        key={idx}
        {...btn}
        typography={{
          size: { base: 16, lg: 18 },
          padding: {
            top: { base: 12, lg: 16 },
            bottom: { base: 12, lg: 16 },
            left: { base: 20, lg: 28 },
            right: { base: 20, lg: 28 },
          },
        }}
      />
    ))
  ) : (
    <Button
      {...button}
      typography={{
        size: { base: 16, lg: 18 },
        padding: {
          top: { base: 12, lg: 16 },
          bottom: { base: 12, lg: 16 },
          left: { base: 20, lg: 28 },
          right: { base: 20, lg: 28 },
        },
      }}
    />
  );
