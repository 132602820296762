import styled from 'styled-components';
import { breakpoint } from 'styles';

import Image from 'components/atoms/Image';
import Typography, { StyledTypography } from 'components/atoms/Typography';
import Container from 'components/molecules/Container';
import { StyledContainer } from 'components/molecules/Container/Container.styles';

export const StyledCaseStudies = styled(Container)`
  position: relative;
  grid-template-rows: repeat(2, auto) 1fr;
  align-items: start;

  ${breakpoint.xxxl} {
    min-height: calc(100vh - 1000px);
  }

  &[data-page='blog'] {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: var(--z-index-zero);
      width: 100%;
      height: 506px;
      clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
      content: '';
      background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0%) 100%);

      ${breakpoint.md} {
        height: 1036px;
        clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
      }

      ${breakpoint.xl} {
        height: 905px;
        clip-path: polygon(0 0, 100% 0%, 100% 70%, 0% 90%);
      }
    }
  }
`;

export const StyledRelatedListsContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: var(--gap-xxs);
  padding: 0;

  ${breakpoint.md} {
    gap: var(--gap-xxxxxl);
  }
`;

export const StyledCaseStudiesHeading = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--gap-xxxxxxl) 0 var(--gap-xxl);

  &[data-variant='blog'] {
    padding: var(--gap-xxxxxxl) 0 0;

    & > * {
      max-width: 525px;
    }
  }

  ${breakpoint.md} {
    flex-direction: row;
    gap: var(--gap-xxxxxl);
    align-items: center;
    padding: 104px 0 var(--gap-xxxxl);
  }

  ${breakpoint.lg} {
    padding: 144px 0 var(--gap-xxxxxxl);
  }

  ${breakpoint.xl} {
    padding: 208px 0 var(--gap-xxxxxxl);
  }

  & > ${StyledTypography} {
    letter-spacing: -0.02em;
  }

  ${StyledTypography} {
    position: relative;
    z-index: 1;
  }
`;

export const StyledContactUsWrapper = styled(StyledContainer)`
  position: relative;
  z-index: var(--z-index-ground);
  padding-top: var(--gap-lg);

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--z-index-underground);
    width: 100%;
    height: 617px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 100%);
    content: '';
    background-color: var(--c-white);

    ${breakpoint.xxl} {
      height: 670px;
    }

    ${breakpoint.xxxl} {
      height: 700px;
    }
  }
`;

export const StyledCareersServicesContainer = styled(StyledContainer)`
  position: relative;
  padding: 0;

  ${breakpoint.md} {
    padding: 0 var(--gap-md) var(--gap-xxxl);
  }

  &::before {
    position: absolute;
    bottom: -264px;
    left: 0;
    z-index: var(--z-index-zero);
    width: 100%;
    height: 638px;
    clip-path: polygon(0 0, 100% 0%, 100% 35%, 0% 55%);
    content: '';
    background: linear-gradient(360deg, #fff -3.85%, rgba(255, 255, 255, 0%) 100%);

    ${breakpoint.md} {
      bottom: -620px;
      clip-path: polygon(0 0, 100% 0%, 100% 60%, 0% 70%);
    }

    ${breakpoint.lg} {
      bottom: -420px;
      height: 850px;
      clip-path: polygon(0 0, 100% 0%, 100% 50%, 0% 60%);
    }

    ${breakpoint.xl} {
      bottom: -200px;
      height: 760px;
      clip-path: polygon(0 0, 100% 0%, 100% 70%, 0% 90%);
    }
  }
`;

export const StyledQuoteContainer = styled(Container)`
  position: relative;

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 711px;
    clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 90%);
    content: '';
    background: linear-gradient(360deg, #fff -3.85%, rgba(255, 255, 255, 0%) 100%);
    transform: translateY(15%);

    ${breakpoint.lg} {
      clip-path: polygon(0 0, 100% 0%, 100% 75%, 0% 85%);
      transform: translateY(12%);
    }

    ${breakpoint.xxl} {
      clip-path: polygon(0 0, 100% 0%, 100% 70%, 0% 85%);
    }
  }
`;

export const StyledOurCaseStudiesContainer = styled(Container)<{ showSectionCut?: boolean }>`
  position: relative;

  ${({ showSectionCut }) =>
    showSectionCut &&
    `
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        display: block;
        width: 100%;
        height: 700px;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 95%);
        content: '';
        background: linear-gradient(360deg, var(--c-white) -3.85%, var(--c-transparent) 100%);
        transform: translateY(-100%);

        ${breakpoint.xl} {
          clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 85%);
        }
      }
    `}
`;

export const StyledAboutUsHeading = styled(Typography)`
  letter-spacing: var(--font-letter-spacing);

  b {
    font-weight: 500;

    ${breakpoint.md} {
      font-size: 60px;
    }

    ${breakpoint.lg} {
      font-size: 72px;
    }

    ${breakpoint.xl} {
      font-size: 96px;
      line-height: 1.13;
    }
  }
`;

export const StyledCaseStudiesImage = styled(Image)`
  position: absolute;
  top: calc(-1 * var(--header-height-mobile));
  right: 0;
  z-index: 0;
  width: 100%;
  max-width: 300px;
  transform: translateX(50%);

  ${breakpoint.md} {
    max-width: 420px;
  }

  ${breakpoint.lg} {
    top: calc(-1 * var(--header-height-desktop));
    max-width: 600px;
  }

  ${breakpoint.xl} {
    max-width: 855px;
  }

  ${breakpoint.xxl} {
    transform: translateX(30%);
  }
`;

export const StyledHomepageFeatureSectionContainer = styled(Container)`
  position: relative;

  &::before {
    position: absolute;
    bottom: -80px;
    left: 0;
    z-index: var(--z-index-zero);
    width: 100%;
    height: 617px;
    clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);
    content: '';
    background: linear-gradient(360deg, var(--c-white) -3.85%, var(--c-transparent) 100%);

    ${breakpoint.md} {
      bottom: -100px;
    }

    ${breakpoint.xxl} {
      bottom: -200px;
    }
  }
`;

export const StyledOpinionCircle = styled(Image)`
  display: none;
  --circle-width: 430px;

  ${breakpoint.md} {
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--z-index-zero);
    display: block;
    width: var(--circle-width);
    height: var(--circle-width);
    transform: translate(-50%, -50%);
  }

  ${breakpoint.lg} {
    transform: translate(-25%, -60%);
  }

  ${breakpoint.xl} {
    transform: translate(10%, -70%);
  }
`;

export const StyledOurCaseContainer = styled(Container)`
  position: relative;
`;

export const StyledOurCaseStudyCircle = styled(Image)`
  display: none;

  ${breakpoint.md} {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: var(--z-index-zero);
    display: block;
    width: 500px;
    height: 1000px;
    overflow: hidden;
    pointer-events: none;

    svg {
      transform: translate(50%, 0);
    }
  }

  ${breakpoint.lg} {
    width: 900px;
    height: 2000px;

    svg {
      transform: translate(45%, 5%);
    }
  }

  ${breakpoint.xl} {
    svg {
      transform: translate(45%, -7%);
    }
  }

  ${breakpoint.xxl} {
    svg {
      transform: translate(30%, -4%);
    }
  }

  ${breakpoint.xxxl} {
    width: 1400px;
    height: 2500px;

    svg {
      transform: translate(5%, 2%);
    }
  }
`;

export const StyledTeammatesContainer = styled(Container)`
  position: relative;

  /* &::before {
    position: absolute;
    bottom: -55px;
    left: 0;
    width: 100%;
    height: 220px;
    clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
    content: '';
    background: linear-gradient(360deg, var(--c-white) -3.85%, var(--c-transparent) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);

    ${breakpoint.lg} {
      bottom: 0;
      height: 330px;
      clip-path: polygon(0 0, 100% 0%, 100% 90%, 0% 100%);
    }

    ${breakpoint.xl} {
      height: 620px;
      clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
    }

    ${breakpoint.xxl} {
      bottom: -130px;
      height: 560px;
      clip-path: polygon(0 0, 100% 0%, 100% 85%, 0% 100%);
    }
  } */
`;

export const StyledCSRelatedListContainer = styled(Container)`
  &::before {
    position: absolute;
    top: -64px;
    left: 0;
    width: 100%;
    height: 450px;
    clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%);
    content: '';
    background: linear-gradient(180deg, var(--c-white) -3.85%, var(--c-transparent) 100%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
`;
