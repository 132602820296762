import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import kebabCase from 'lodash/kebabCase';

import { RelatedListType } from 'components/organisms/RelatedList';

export const useCaseStudiesFilter = (tag: string, list: RelatedListType[]) => {
  const location = useLocation();

  const [filteredList, setFilteredList] = useState<RelatedListType[]>([...list]);

  useEffect(() => {
    if (tag !== '') {
      setFilteredList([...list.filter((el) => kebabCase(el.tag) === kebabCase(tag))]);
    } else {
      setFilteredList([...list]);
    }

    return () => setFilteredList([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag, location, list]);

  return filteredList;
};
