import {
  ContentfulRichTextGatsbyReference,
  RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';

import { ButtonType } from 'components/atoms/Button';
import { ImageType } from 'components/atoms/Image';
import { PageLinkType } from 'components/atoms/Link';
import { CardProps } from 'components/molecules/Card';
import { PublisherType } from 'components/molecules/Publisher';

/* 
  PRIMARY = simple with proportions
  SECONDARY = with button and scrolled on mobile
  TERTIARY = people with glass effect behind cards
  SECONDARY_REVERSE = with button and text on left
  OUR_CASE_STUDY = homepage our case study
*/

export enum RelatedListVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  SECONDARY_REVERSE = 'secondary-reverse',
  OUR_CASE_STUDY = 'our-case-study',
}

export type RelatedListCard = {
  link?: PageLinkType;
  tag?: string;
  publisher?: PublisherType;
  subtitle?: string;
} & Pick<CardProps, 'title' | 'image' | 'variant'>;

export type RelatedListType = {
  id: string;
  tag: string;
  card: RelatedListCard;
  order?: number;
};

export type RelatedListProps = {
  list: RelatedListType[] | RelatedListCard[];
  variant?: RelatedListVariant;
  proportions?: string;
  heading?: string;
  button?: ButtonType | ButtonType[];
  description?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  className?: string;
  addExtraPadding?: boolean;
  image?: ImageType;
  testId?: string;
};

export type ContentfulRelatedListProps = {
  relatedSites: RelatedListType[] | RelatedListCard[];
  title?: string;
  description?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
  button?: ButtonType[];
  proportions?: string;
  variant?: RelatedListVariant;
  circleLogo?: ImageType;
};
