import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
import Typography from 'components/atoms/Typography';
import Card, { CardVariant } from 'components/molecules/Card';

import { descriptionProps, renderButtons } from './RelatedList.constants';
import {
  RelatedGlassTile,
  StyledButtonsWrapper,
  StyledCircleImage,
  StyledContainer,
  StyledRelatedList,
  StyledWrapper,
} from './RelatedList.styles';

import { RelatedListCard, RelatedListProps, RelatedListType, RelatedListVariant } from './models.d';

const RelatedList = ({
  variant = RelatedListVariant.PRIMARY,
  list,
  proportions = '1',
  heading,
  description,
  button,
  className,
  addExtraPadding = false,
  image: bgImage,
  testId,
  ...delegated
}: RelatedListProps) => {
  const proportion = proportions.split(' ').map((e) => e.split(':').map((el) => `${el.trim()}fr`));
  const proportionValue = proportion.map((el) => el.join(' '));

  if (!list.length) {
    return null;
  }
  const { PRIMARY, SECONDARY, TERTIARY, SECONDARY_REVERSE, OUR_CASE_STUDY } = RelatedListVariant;

  const isPrimaryVariant = variant === PRIMARY;
  const isSecondaryVariant = variant === SECONDARY;
  const isTeritiaryVariant = variant === TERTIARY;
  const isSecondaryReverse = variant === SECONDARY_REVERSE;
  const isOurCaseStudyVariant = variant === OUR_CASE_STUDY;

  return (
    <StyledContainer {...{ ...delegated, addExtraPadding, testId }} noPadding contentWidth={1440}>
      <StyledWrapper className={className} data-variant={variant}>
        {heading && !isPrimaryVariant && !isSecondaryReverse ? (
          <Typography
            asEl="h2"
            letterSpacing="-0.02em"
            align={isTeritiaryVariant ? 'center' : 'left'}
            size={{ base: 24, md: 30, lg: 48 }}
            padding={{ bottom: { base: 32, md: 40 } }}
            fontWeight={500}
            color="gray-900"
          >
            {heading}
          </Typography>
        ) : null}
        {isSecondaryReverse || isOurCaseStudyVariant ? (
          <div className="button-section">
            {heading ? (
              <Typography
                size={72}
                lineHeight={1.3}
                color="gray-800"
                fontWeight={500}
                transform="uppercase"
                padding={{ bottom: 8 }}
                letterSpacing="-0.02em"
              >
                {heading}
              </Typography>
            ) : null}
            {description ? (
              <Typography {...descriptionProps[variant]}>{renderRichText(description)}</Typography>
            ) : null}
            {!isOurCaseStudyVariant && !Array.isArray(button) ? (
              <Button {...button} label={button?.label} />
            ) : null}
          </div>
        ) : null}
        {proportionValue.map((oneProportion, idx) => (
          <StyledRelatedList
            key={idx}
            data-variant={variant}
            style={
              {
                '--proportion': oneProportion,
              } as React.CSSProperties
            }
          >
            {isTeritiaryVariant ? (
              <RelatedGlassTile data-variant="global" data-glass-variant="light" />
            ) : null}
            {isTeritiaryVariant && bgImage ? <StyledCircleImage {...bgImage} /> : null}
            {(isOurCaseStudyVariant ? [...list].splice(idx * 2, 2) : list).map((item, index) => {
              const {
                link: localLink,
                image,
                title,
                variant: localVariant,
                subtitle,
                publisher,
              } = (item as RelatedListType).card ?? (item as RelatedListCard);

              const selectedVariant =
                variant.includes(SECONDARY) && localVariant?.includes(CardVariant.TERTIARY)
                  ? CardVariant.TERTIARY
                  : localVariant;

              if (localLink) {
                return (
                  <Link
                    key={`related-item-${index}`}
                    to={localLink}
                    ariaLabel={localLink.ariaLabel}
                  >
                    <Card
                      {...{ image, title, subtitle, publisher }}
                      badge={!isSecondaryVariant && item?.tag ? item.tag : undefined}
                      variant={selectedVariant}
                      titleAsEl={isPrimaryVariant && !heading ? 'h2' : undefined}
                    >
                      {isTeritiaryVariant ? <RelatedGlassTile /> : null}
                      <Button
                        tabIndex={-1}
                        label={localLink.label}
                        variant="tertiary"
                        icon={{ name: 'common-arrow-right-black' }}
                        typography={{
                          padding: {
                            top: { base: 16, lg: 12 },
                            bottom: { base: 16, lg: 12 },
                            right: { base: 28, lg: 20 },
                            left: { base: 28, lg: 20 },
                          },
                          size: { base: 16, lg: 18 },
                          fontWeight: 500,
                          color: 'gray-900',
                        }}
                      />
                    </Card>
                  </Link>
                );
              }

              return (
                <Card
                  key={`related-item-${index}`}
                  {...{ image, title, variant: localVariant }}
                  badge={isPrimaryVariant && item?.tag ? item.tag : undefined}
                >
                  <>
                    {isTeritiaryVariant ? (
                      <RelatedGlassTile data-variant="local" data-glass-variant="light" />
                    ) : null}
                    {subtitle ? (
                      <Typography
                        {...{
                          asEl: 'span',
                          padding: { top: { base: 10, lg: 16 } },
                          align: 'center',
                          color: 'gray-700',
                        }}
                      >
                        {subtitle}
                      </Typography>
                    ) : null}
                  </>
                </Card>
              );
            })}
          </StyledRelatedList>
        ))}
        {isSecondaryVariant && button ? renderButtons(button) : null}
        {isOurCaseStudyVariant && Array.isArray(button) && button.length ? (
          <StyledButtonsWrapper pt={{ base: 32, lg: 64, xl: 80 }}>
            {renderButtons(button)}
          </StyledButtonsWrapper>
        ) : null}
      </StyledWrapper>
    </StyledContainer>
  );
};

export default RelatedList;
